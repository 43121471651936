import { get } from 'lodash';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { VisitorModel } from 'client/data/models/visitor';

export function getTreehouseEligiblePath() {
  return 'treehouseEligible';
}

export const TreehouseModel = createModelSegment('treehouse', [
  /**
   * @see getTreehouseEligiblePath
   * @returns {boolean}
   */
  {
    path: 'treehouseEligible',
    async resolve(match, context) {
      const { zipCode } = await context.resolveValue('location', VisitorModel);

      return withMetrics(EdmundsAPI, context)
        .fetchJson(`/ev-install/v1/coverage/${zipCode}`)
        .then(response => !!get(response, 'results'))
        .catch(() => false);
    },
  },
]);
