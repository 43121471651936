import PropTypes from 'prop-types';
import { transform, get } from 'lodash';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import { isUnknownZip } from 'site-modules/shared/utils/location';
import { filterFleetTrims } from 'site-modules/shared/utils/core-page/fleet-trims';
import { VisitorModel } from './visitor';

const trimSummaryPageSize = 25;
const trimSummaryPhotoWidth = 500;
const trimSummaryRadius = 100;

export function buildCoreConfigurationsPath({ make, model, year, submodel }) {
  if (!(make && model && year && submodel)) {
    return null;
  }
  return `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].coreConfigurations`;
}

export function buildTrimSummariesPath({ make, model, year, submodel }) {
  if (!(make && model && year && submodel)) {
    return null;
  }
  return `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].trimSummaries`;
}

export function buildTrimSummariesWithTaxesFeesPath({ make, model, year, submodel }) {
  if (!(make && model && year && submodel)) {
    return null;
  }
  return `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].trimSummariesWithTaxesFees`;
}

async function resolveTrimSummaries({ make, model, year, submodel }, context, { includeTaxesAndFees } = {}) {
  const visitorLocation = await context.resolveValue('location', VisitorModel);
  const { zipCode, latitude, longitude, dma } = visitorLocation;

  const isInternational = isUnknownZip(visitorLocation);

  const query = objectToQueryString({
    submodel: submodel === 'default' ? '' : submodel,
    zip: zipCode,
    lat: latitude,
    lon: longitude,
    userDma: dma,
    radius: trimSummaryRadius,
    pageSize: trimSummaryPageSize,
    photoWidth: trimSummaryPhotoWidth,
    includeTaxesAndFees,
    subtractOptionPrices: true,
    useLowestPricedStyle: true,
    international: isInternational ? true : null,
  });

  const url = `/vehicle-extension/v1/trim-summary/${make}/${model}/${year}?${query}`;
  const result = await withMetrics(EdmundsAPI, context).fetchJson(url, { timeout: 650 });

  return filterFleetTrims(
    transform(
      get(result, 'trims'),
      (accumulator, trimData, trimName) => {
        accumulator.push({
          trim: trimName,
          ...trimData,
        });
      },
      []
    ),
    context
  );
}

/**
 * This /model/ is for the core-configuration API used by Groundwork Web to power the pricing module on the
 * core page.  This api has not yet been optimized for venom/luckdragon, which is why this is not combined
 * into the inventory.js /model/.  When this is optimized for venom/luckdragon, we should consider moving
 * this into the inventory.js model.
 */
export const InventoryCoreConfigurationsModel = createModelSegment('inventoryCoreConfigurations', [
  /**
   * @return CoreConfiguration[] - PropTypes.arrayOf(CoreConfiguration)
   *
   * path: InventoryCoreConfigurationsPaths.buildCoreConfigurationsPath
   * api: https://qa-11-www.edmunds.com/api/inventory/v5/core-configurations/honda/accord/2017/coupe?lease-config=true&priceDiff=800&radius=50.0&zip=58067&lat=46.054802&lon=-97.502278&userDma=724
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].coreConfigurations',

    async resolve({ make, model, year, submodel }, context) {
      const { zipCode, dma, latitude, longitude } = await context.resolveValue('location', VisitorModel);

      const dmaParam = dma ? `&userDma=${dma}` : '';

      const url = `/inventory/v5/core-configurations/${make}/${model}/${year}/${submodel}?zip=${zipCode}&lat=${latitude}&lon=${longitude}${dmaParam}&priceDiff=800&radius=50.0`;
      const result = await withMetrics(EdmundsAPI, context).fetchJson(url);

      const coreConfigurations = result && result.response && result.response.configurations;
      return coreConfigurations || [];
    },
  },
  /**
   * @return trimSummaries[] - PropTypes.arrayOf(TrimSummary)
   *
   * path: buildTrimSummariesPath
   * api: https://www.edmunds.com/api/vehicle-extension/v1/trim-summary/jaguar/f-pace/2020?submodel=suv&zip=90404&lat=34.028496&lon=-118.470151&userDma=803&radius=50&pageSize=25&photoWidth=500&subtractOptionPrices=true&useLowestPricedStyle=true
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].trimSummaries',

    async resolve({ make, model, year, submodel }, context) {
      return resolveTrimSummaries({ make, model, year, submodel }, context);
    },
  },
  /**
   * The only difference from trimSummaries is includeTaxesAndFees which adds taxesAndFees to prices
   * @return trimSummaries[] - PropTypes.arrayOf(TrimSummary)
   *
   * path: buildTrimSummariesWithTaxesFeesPath
   * api: https://www.edmunds.com/api/vehicle-extension/v1/trim-summary/jaguar/f-pace/2020?submodel=suv&zip=90404&lat=34.028496&lon=-118.470151&userDma=803&radius=50&pageSize=25&photoWidth=500&includeTaxesAndFees=true&subtractOptionPrices=true&useLowestPricedStyle=true
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].trimSummariesWithTaxesFees',

    async resolve({ make, model, year, submodel }, context) {
      const transformedTrimSummaries = await resolveTrimSummaries({ make, model, year, submodel }, context, {
        includeTaxesAndFees: true,
      });

      return context
        .updateValue(buildTrimSummariesPath({ make, model, year, submodel }), transformedTrimSummaries)
        .then(() => ({
          $ref: `#/makes/${make}/models/${model}/submodels/${submodel}/years/${year}/trimSummaries`,
        }));
    },
  },
]);

export const CoreConfigurationLabels = {
  BASIC: 'basic',
  WELL_EQUIPPED: 'wellEquipped',
  LOADED: 'loaded',
};

const CoreConfiguration = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  style: PropTypes.string,
  trim: PropTypes.string,
  inventoryCount: PropTypes.number,
  lowestPrice: PropTypes.number,
  estimatedSavings: PropTypes.number,
  estimatedPrice: PropTypes.number,
  baseMsrp: PropTypes.number,
  totalMsrp: PropTypes.number,
  tmv: PropTypes.number,
  styleEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

const TrimSummary = PropTypes.shape({
  styleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  trimName: PropTypes.string,
  hasLeadFormDealers: PropTypes.bool,
  inventoryCount: PropTypes.number,
  pricing: PropTypes.shape({
    suggestedPrice: PropTypes.number,
    estimatedSavings: PropTypes.number,
    baseMsrp: PropTypes.number,
    totalMsrp: PropTypes.number,
    tmv: PropTypes.number,
    totalTmv: PropTypes.number,
    invoicePrice: PropTypes.number,
    taxesAndFees: PropTypes.number,
    optionsBaseMsrp: PropTypes.number,
    optionsTotalTmv: PropTypes.number,
    optionsInvoicePrice: PropTypes.number,
  }),
  photo: PropTypes.shape({
    title: PropTypes.string,
    provider: PropTypes.string,
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.shape({
          href: PropTypes.string,
        }),
      })
    ),
  }),
});

export const InventoryCoreConfigurationEntities = {
  CoreConfigurations: PropTypes.arrayOf(CoreConfiguration),
  CoreConfiguration,
};

export const InventoryTrimSummaryEntities = {
  TrimSummaries: PropTypes.arrayOf(TrimSummary),
  TrimSummary,
};
